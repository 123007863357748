<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('edit')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-form id="editor" ref="editor" v-else>
            <v-row>
                <!-- general -->
                <v-col cols="12" sm="6">
                    <DashCard title="Descripción">
                        <v-row>
                            <v-col cols="12">
                                <v-label>Actividad</v-label>
                                <v-textarea hide-details flat dense solo outlined v-model="lv.actividad" :rules="[rules.required]" />
                            </v-col>
                            <v-col cols="12">
                                <EditorResponsable v-model="lv" :personas="personas" :rules="[rules.required]" obj-name="coordinador_inicial" />
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" sm="6">
                    <DashCard title="General">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-label>ID SAP-EHS</v-label>
                                <v-text-field hide-details flat dense solo outlined type="number" v-model.number="lv.cod_sapehs" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-label>Estado</v-label>
                                    <v-select 
                                        v-model="lv.estado_actividad" 
                                        :rules="[rules.required]" 
                                        dense 
                                        solo 
                                        flat
                                        hide-details 
                                        outlined 
                                        :items="estados_general" 
                                        item-text="text"
                                        :return-obejct="false" 
                                        item-value="value"></v-select>
                                </v-col>
                            <v-col cols="12" sm="6">
                                <v-label>Ejecución</v-label>
                                <DatePickerText flat hide-details dense :rules="[rules.required]" v-model="lv.fecha_ejecucion" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-label>Area</v-label>
                                <Selector dense 
                                        :rules="[rules.required]"
                                        v-model="lv.area" 
                                        :items="areas" 
                                        return-object
                                        label="Area"
                                        create-dialog-title="Crear nueva area" 
                                        create-dialog-placeholder="Nombre de la area"
                                        item-text="nombre" />
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12">
                    <DashCard title="Tareas" body-class="pa-0" :actions="true" actions-align="right">
                        <v-data-table :headers="tarea_header" :items="lv.tareas" :mobile-breakpoint="0" :items-per-page="1000" hide-default-footer>
                            <template v-slot:item.vencimiento="{ item }">{{ item.vencimiento | moment("L") }}</template>
                            <template v-slot:item.cierre="{ item }">{{ item.cierre | moment("L") }}</template>
                            <template v-slot:item.estado="{ item }">
                                <v-chip dark :color="item.estado | color_estado('tarea')">
                                    {{ item.estado | estado('tarea') }}
                                </v-chip>
                            </template> 
                            <template v-slot:item.acciones="{ item }">
                                <v-btn v-if="!editMode" text fab small color="primary" depressed @click="lv.tareas.splice(lv.tareas.indexOf(item), 1)">
                                    <v-icon>close</v-icon>
                                </v-btn>
                                <v-btn text fab small color="primary" depressed @click="$refs.editor_tarea.show(true, item, lv.tareas.indexOf(item))">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                        <template slot="actions">
                            <v-btn large depressed color="secondary" @click="$refs.editor_tarea.show(false)" form="editor"> 
                                <span class="subtitle-2">Añadir</span>
                            </v-btn>
                        </template>
                    </DashCard>
                </v-col>
                <v-col cols="12">
                    <DashCard :actions="true" actions-align="right">
                        <template slot="actions">
                            <v-btn large depressed color="primary" @click="subir" form="editor"> 
                                <span class="subtitle-2">Guardar cambios</span>
                            </v-btn>
                            <v-btn large text color="primary" @click="askCancelar">
                                <span class="subtitle-2">Cancelar</span>
                            </v-btn>
                        </template>
                    </DashCard>
                </v-col>
            </v-row>
        </v-form>
        
        <DialogTarea ref="editor_tarea" @success="add_tarea" />
        <Message ref="success_dialog" @onClose="$router.back()" />
        <Message ref="error_dialog" />
        <CommonDialog ref="cancel_dialog" @onClose="on_cancel_close" />
    </div>
</template>

<script>
import raw_estados from '@/assets/estados'
import { axiosHelper, messages, valid_required } from '@/helpers'
import EditorResponsable from '@/dash/components/EditorResponsable'
import DialogTarea from '@/dash/dialogs/DialogTarea'
import CommonDialog from '@/shared/components/CommonDialog'
import Message from '@/shared/components/Message'
import DashCard from '@/shared/components/DashCard'
import DatePickerText from '@/shared/components/DatePickerText'
import AccessMessage from '@/dash/components/AccessMessage'
import Selector from '@/dash/components/Selector'

export default {
    components: {
        Message,
        DialogTarea,
        CommonDialog,
        DashCard,
        DatePickerText,
        Selector,
        EditorResponsable,
        AccessMessage
    },
    data: () => ({
        rules: {
            required: value => valid_required(value)
        },
        editor_data: {},
        lv: { tareas: [] },
        tarea_header: 
        [
            { text: "Tarea", value: "texto", sortable: false },
            { text: "Estado", value: "estado", sortable: false },
            { text: "Vencimiento", value: "vencimiento", sortable: false },
            { text: "Cierre", value: "cierre", sortable: false },
            { text: "Acciones", value: "acciones", sortable: false, align: 'center' }
        ]
    }),
    computed: {
        editMode() {
            return this.$route.meta.editMode;
        },
        estados_general() {
            return raw_estados['actividad'];
        },
        estados_tarea() {
            return raw_estados['tarea'];
        },
        areas() {
            return this.editor_data.areas || [];
        },
        personas() {
            return this.editor_data.personas || [];
        }
    },
    mounted() {
        let text = "";

        if(!this.$parent.$parent.get_permission('edit')) return;
        
        if(this.$route.meta.editMode) {
            text = "Editar Compromiso de seguridad";
            axiosHelper.get('liderazgos/' + this.$route.params.id + '/editar')
                .then(response => {
                    this.editor_data = response.data;
                    this.lv = this.editor_data.liderazgo;
                })
        } else {
            text = "Añadir Compromiso de seguridad";
            axiosHelper.get('liderazgos/crear')
                .then(response => this.editor_data = response.data)
        }

        this.$emit("title", { text: text, icon: "edit" });
    },
    methods: {
        add_tarea(item, edit, index) {
            if(!edit) {
                this.lv.tareas.push(item);
            } else {
                this.$set(this.lv.tareas, index, item);
                this.$forceUpdate();
            }
        },
        on_cancel_close(e) {
            if(e == "yes") {
                this.$router.back();
            }
        },
        subir(e) {
            e.preventDefault();

            if(!this.$refs.editor.validate()) {
                this.$refs.error_dialog.show(messages.titles["error_upload_editor"], messages.texts["error_check_fields"], "error")
                return;
            }

            if(this.lv.tareas.length < 1) {
                this.$refs.error_dialog.show(messages.titles["error_upload_editor"], messages.texts["error_lv_cero_tareas"], "error")
                return;
            }
            if(this.editMode) {
                axiosHelper.put('liderazgos/' + this.$route.params.id + '/', this.lv)
                .then(response => this.success())
                .catch(error => this.error());
            } else {
                axiosHelper.post('liderazgos/', this.lv)
                .then(response => this.success())
                .catch(error => this.error());
            }
        },
        askCancelar(e) {
            e.preventDefault();
            this.$refs.cancel_dialog.showDialog(
                messages.titles[this.editMode ? "question_cancel_edit" : "question_cancel_new"], 
                messages.texts[this.editMode ? "question_cancel_edit" : "question_cancel_new"], "yes_no");
        },
        success() {
            this.$refs.success_dialog.show(
                messages.titles["success_generico"],
                messages.texts[this.editMode ? "success_upload_edit" : "success_upload_new"], 
                "success");
        },
        error() {
            this.$refs.error_dialog.show(
                messages.titles["error_generico"], 
                messages.texts["error_upload"], 
                "error");
        }
    }
}
</script>